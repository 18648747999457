@import 'normalize/import-now';

body, h1 {
    margin: 0;
    padding: 0;
}

html, body { height: 100%; }
#wrap { min-height: 100%; }
#main { overflow: auto; padding-bottom: 150px; }

#after_content {
    height: 35px;
}

#footer {
    position: relative;
    margin: -150px auto 0;
    width: 940px;
    height: 150px;
    clear: both;
    background: #000 url(../img/footer.png) 100px 15px no-repeat;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

/* GRID */

.grid_1 { width:60px; }
.grid_2 { width:140px; }
.grid_3 { width:220px; }
.grid_4 { width:300px; }
.grid_5 { width:380px; }
.grid_6 { width:460px; }
.grid_7 { width:540px; }
.grid_8 { width:620px; }
.grid_9 { width:700px; }
.grid_10 { width:780px; }
.grid_11 { width:860px; }
.grid_12 { width:940px; }

.column {
    margin: 0 10px;
    overflow: hidden;
    float: left;
    display: inline;
}
.row {
    width: 960px;
    margin: 0 auto;
    overflow: hidden;
}
.row .row {
    margin: 0 -10px;
    width: auto;
    display: inline-block;
}

/* VARIOUS TAGS */

p {
    margin: 14px 0;
    padding: 0;
}

a {
    color: #66ccff;
}

a:hover {
    color: #fff;
}

ul, ol {
    font-size: 14px;
}

/* legions */

body {
    font-family: Arial, Sans;
}

body.nivosus {
    background: #000 url(../img/back_1.jpg) top center no-repeat;
}

body.nivosus_home {
    background: #000 url(../img/back_2.jpg) top center no-repeat;
}

h2 {
    margin: 30px 0 0;
    height: 114px;
    padding: 0;
    font-size: 72px;
    text-transform: uppercase;
    text-indent: -1000em;
}

h2 a {
    display: block;
    margin: 0 12px;
}

a#title_blog {
    background: url(../img/title_blog.png) no-repeat 0 0;
    height: 84px;
}

a#title_faq {
    background: url(../img/title_faq.png) no-repeat 0 0;
    height: 101px;
}

h3 {
    margin: 0 0;
    padding: 0;
    color: #eaeaea;
    font-size: 18px;
    text-transform: uppercase;
}

h3 a {
    text-decoration: none;
}

h4 {
    margin: 0 0;
    padding: 0;
    color: #eaeaea;
    font-size: 14px;
}

label {
    display: block;
    color: #eaeaea;
}

/* HEADER AND NAVIGATION */

body h1 {
    float: left;
}

#nav {
    background: url(../img/nav.png) top left repeat-x;
    height: 81px;
    min-width: 960px;
    overflow: hidden;
}

#nav .row {
    background: url(../img/nav_blue.png) top right no-repeat;
}

h1 a {
    background: url(../img/legions.png) top left no-repeat;
    width: 230px;
    height: 71px;
    display: block;
    overflow: hidden;
    text-indent: -1000em;
    float: left;
    margin-left: 17px;
}

h1 a:hover {
    background: url(../img/legions.png) top right no-repeat;
}

#nav ul {
    width: 692px;
    margin: 45px 0 0 268px;
    padding: 0;
    list-style: none;
    float: left;
    position: absolute;
}

#nav li {
    text-transform: uppercase;
    float: left;
}

#nav li a {
    margin-right: 35px;
    text-decoration: none;
    color: #66ccff;
}

#nav li a:hover {
    color: #fff;
}

#nav span {
    font-size: 10px;
    color: #b5b5b5;
    text-transform: uppercase;
    float: left;
    position: relative;
    top: 10px;
}

#nav span.left {
    margin-left: 20px;
}

#nav span.right {
    text-align: right;
    float: right;
    position: relative;
    margin-right: 50px;
}

#nav span a {
    color: #66ccff;
    text-decoration: none;
}

#nav span a:hover {
    color: #fff;
}

/* BLOCK STYLING */

.block {
    display: block;
    background: #000;
    background: linear-gradient(
        to top,
        rgba(0,0,0,0.8) 50%,
        rgba(0,0,0,1) 100%
    );
    color: #ddd;
    padding: 7px 0;
    margin: 20px 0;
    box-shadow: 0px 2px 3px #000;
}

.block p {
    margin: 14px 10px;
    font-size: 14px;
    line-height: 18px;
    color: #8a8a8a;
}

.block h3 {
    margin: 0 10px;
}

.block h4 {
    margin: 14px 10px;
}

.block ul, .block ol {
    margin: 14px 10px;
    color: #8a8a8a;
}

.block li {
    margin: 0 0 4px;
}

/* BLOG */

.post_date {
    margin: 0 10px;
    font-size: 12px;
    text-transform: uppercase;
}

.post_link {
    margin: 0 10px;
    padding: 4px 10px;
    background: #000;
    margin: 21px 0 7px;
    text-transform: uppercase;
    overflow: hidden;
}

.post_link a {
    float: right;
    text-decoration: none;
    color: #fff;
}

.pagination {
    text-transform: uppercase;
    overflow: hidden;

    a {
        text-decoration: none;
        color: #fff;
    }

    .next {
        float: right;
    }
}

/* SIDEBARS */

.linklist {
    list-style: none;
    margin: 14px 0;
    padding: 0;
}

/* FRONT PAGE */

#the_return {
    background: url(../img/the_return.png) no-repeat 0 0;
    height: 115px;
    width: 893px;
    margin: 130px 33px;
}

#download_button {
    display: block;
    height: 80px;
    width: 270px;
    text-indent: -100em;
    overflow: hidden;
    margin: 25px auto;
    background: #000 url(../img/download_button.png) no-repeat 50% 50%;
    border-radius: 5px;
    box-shadow: 0 0 10px 3px #b6ffff;
}

#title_homepage {
    background: url(../img/title_home.png) no-repeat 0 0;
    height: 132px;
    width: 512px;
    margin: 95px 0 20px 450px;
}
