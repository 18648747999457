/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  line-height: 1.15;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Correct the padding in Firefox.
     */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

body, h1 {
  margin: 0;
  padding: 0; }

html, body {
  height: 100%; }

#wrap {
  min-height: 100%; }

#main {
  overflow: auto;
  padding-bottom: 150px; }

#after_content {
  height: 35px; }

#footer {
  position: relative;
  margin: -150px auto 0;
  width: 940px;
  height: 150px;
  clear: both;
  background: #000 url(../img/footer.png) 100px 15px no-repeat;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }

/* GRID */
.grid_1 {
  width: 60px; }

.grid_2 {
  width: 140px; }

.grid_3 {
  width: 220px; }

.grid_4 {
  width: 300px; }

.grid_5 {
  width: 380px; }

.grid_6 {
  width: 460px; }

.grid_7 {
  width: 540px; }

.grid_8 {
  width: 620px; }

.grid_9 {
  width: 700px; }

.grid_10 {
  width: 780px; }

.grid_11 {
  width: 860px; }

.grid_12 {
  width: 940px; }

.column {
  margin: 0 10px;
  overflow: hidden;
  float: left;
  display: inline; }

.row {
  width: 960px;
  margin: 0 auto;
  overflow: hidden; }

.row .row {
  margin: 0 -10px;
  width: auto;
  display: inline-block; }

/* VARIOUS TAGS */
p {
  margin: 14px 0;
  padding: 0; }

a {
  color: #66ccff; }

a:hover {
  color: #fff; }

ul, ol {
  font-size: 14px; }

/* legions */
body {
  font-family: Arial, Sans; }

body.nivosus {
  background: #000 url(../img/back_1.jpg) top center no-repeat; }

body.nivosus_home {
  background: #000 url(../img/back_2.jpg) top center no-repeat; }

h2 {
  margin: 30px 0 0;
  height: 114px;
  padding: 0;
  font-size: 72px;
  text-transform: uppercase;
  text-indent: -1000em; }

h2 a {
  display: block;
  margin: 0 12px; }

a#title_blog {
  background: url(../img/title_blog.png) no-repeat 0 0;
  height: 84px; }

a#title_faq {
  background: url(../img/title_faq.png) no-repeat 0 0;
  height: 101px; }

h3 {
  margin: 0 0;
  padding: 0;
  color: #eaeaea;
  font-size: 18px;
  text-transform: uppercase; }

h3 a {
  text-decoration: none; }

h4 {
  margin: 0 0;
  padding: 0;
  color: #eaeaea;
  font-size: 14px; }

label {
  display: block;
  color: #eaeaea; }

/* HEADER AND NAVIGATION */
body h1 {
  float: left; }

#nav {
  background: url(../img/nav.png) top left repeat-x;
  height: 81px;
  min-width: 960px;
  overflow: hidden; }

#nav .row {
  background: url(../img/nav_blue.png) top right no-repeat; }

h1 a {
  background: url(../img/legions.png) top left no-repeat;
  width: 230px;
  height: 71px;
  display: block;
  overflow: hidden;
  text-indent: -1000em;
  float: left;
  margin-left: 17px; }

h1 a:hover {
  background: url(../img/legions.png) top right no-repeat; }

#nav ul {
  width: 692px;
  margin: 45px 0 0 268px;
  padding: 0;
  list-style: none;
  float: left;
  position: absolute; }

#nav li {
  text-transform: uppercase;
  float: left; }

#nav li a {
  margin-right: 35px;
  text-decoration: none;
  color: #66ccff; }

#nav li a:hover {
  color: #fff; }

#nav span {
  font-size: 10px;
  color: #b5b5b5;
  text-transform: uppercase;
  float: left;
  position: relative;
  top: 10px; }

#nav span.left {
  margin-left: 20px; }

#nav span.right {
  text-align: right;
  float: right;
  position: relative;
  margin-right: 50px; }

#nav span a {
  color: #66ccff;
  text-decoration: none; }

#nav span a:hover {
  color: #fff; }

/* BLOCK STYLING */
.block {
  display: block;
  background: #000;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 50%, black 100%);
  color: #ddd;
  padding: 7px 0;
  margin: 20px 0;
  box-shadow: 0px 2px 3px #000; }

.block p {
  margin: 14px 10px;
  font-size: 14px;
  line-height: 18px;
  color: #8a8a8a; }

.block h3 {
  margin: 0 10px; }

.block h4 {
  margin: 14px 10px; }

.block ul, .block ol {
  margin: 14px 10px;
  color: #8a8a8a; }

.block li {
  margin: 0 0 4px; }

/* BLOG */
.post_date {
  margin: 0 10px;
  font-size: 12px;
  text-transform: uppercase; }

.post_link {
  margin: 0 10px;
  padding: 4px 10px;
  background: #000;
  margin: 21px 0 7px;
  text-transform: uppercase;
  overflow: hidden; }

.post_link a {
  float: right;
  text-decoration: none;
  color: #fff; }

.pagination {
  text-transform: uppercase;
  overflow: hidden; }
  .pagination a {
    text-decoration: none;
    color: #fff; }
  .pagination .next {
    float: right; }

/* SIDEBARS */
.linklist {
  list-style: none;
  margin: 14px 0;
  padding: 0; }

/* FRONT PAGE */
#the_return {
  background: url(../img/the_return.png) no-repeat 0 0;
  height: 115px;
  width: 893px;
  margin: 130px 33px; }

#download_button {
  display: block;
  height: 80px;
  width: 270px;
  text-indent: -100em;
  overflow: hidden;
  margin: 25px auto;
  background: #000 url(../img/download_button.png) no-repeat 50% 50%;
  border-radius: 5px;
  box-shadow: 0 0 10px 3px #b6ffff; }

#title_homepage {
  background: url(../img/title_home.png) no-repeat 0 0;
  height: 132px;
  width: 512px;
  margin: 95px 0 20px 450px; }
